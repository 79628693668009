<template>
  <div>
    <div class="header">JSON</div>
    <textarea v-model="jsonString" :class="classObject" class="textarea" :style="{ background: invalidJson ? '#fee' : '#fff' }"></textarea>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'surveyjs-creator-json',
  computed: {
    classObject() {
      return {
        /*
        * set the class "sd-input" only if the theme "defaultV2" is used
        */
        'sd-input': this.question.isDefaultV2Theme
      }
    }
  },
  data: () => ({
    invalidJson: false,
    jsonString: null
  }),
  props: {
    question: Object
  },
  methods: {
    
  },
  watch: {
    jsonString(jsonString) {
      try {
        this.question.value = JSON.parse(jsonString)
        this.invalidJson = false
      } catch {
        this.invalidJson = true
      } 
    }
  },
  async beforeMount() {
    this.jsonString = JSON.stringify(this.question.value || null)
  }
}
</script>

<style lang="scss" scoped>
  .header {
    color: #aaa;
    padding: 0 0 3px;
  }
  
  .textarea {
    height: 200px;
  }
</style>
