import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'

import { updateLocaleSource } from '@/i18n'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/Error.vue')
  },
  {
    path: '/:source/survey/:survey',
    name: 'surveyDeprecatedPath1',
    beforeEnter: async (to, from, next) => {
      next({
        name: 'survey',
        params: {
          source: to.params.source,
          survey: to.params.survey,
          id: to.query.id,
          pw: to.query.pw
        },
        query: _.omit(to.query, ['id', 'pw'])
      })
    }
  },
  {
    path: '/:source/:survey/:id/:pw',
    name: 'survey',
    beforeEnter: async (to, from, next) => {
      next({
        name: store.state.survey.showHomepage ? 'surveyHome' : 'surveyForm',
        params: to.params,
        query: to.query
      })
    }
  },
  {
    path: '/:source/:survey/:id/:pw/survey',
    name: 'surveyForm',
    component: () => import('@/views/SurveyForm.vue')
  },
  {
    path: '/:source/:survey/:id/:pw/home',
    name: 'surveyHome',
    component: () => import('@/views/SurveyHome.vue')
  },
  { 
    path: '*',
    redirect: { name: 'home' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  try {
    let query = to.query
    let params = to.params

    if (query.cati !== undefined) {
      store.dispatch('setCati', !!query.cati)
    }

    if (query.timeoutKey !== undefined) {
      store.dispatch('setTimeoutKey', query.timeoutKey)
    }
    
    if (
      to.name == 'surveyDeprecatedPath1' ||
      to.name == 'error'
    ) {
      return next()
    }

    if (params.id && params.pw) {
      store.dispatch('setDataId', params.id)
      store.dispatch('setPw', params.pw)
      
      const sourceName = params.source
      const surveyName = params.survey

      await updateLocaleSource(sourceName, surveyName)

      await store.dispatch('setSource', sourceName)
      await store.dispatch('getSurvey', surveyName)
      
      await store.dispatch('getStartupData')
    }

    return next()    
  } catch(e) {
    console.error(e)
    throw e
  }
})

export default router