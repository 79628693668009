import router from '@/router'
import store from '@/store'
import api from '@/services/api'
import _ from 'lodash'

function SurveyError(errObj, displayData, contextData) {
  this._SurveyError = true
  
  this.message = errObj || new Error('SurveyError')

  this.displayData = Object.assign({
    // display data
    title: 'error',
    description: 'there_has_been___',
    i18n: true,
    dialog: false,
    silent: false
  }, displayData || {})

  this.contextData = contextData
}

SurveyError.prototype = Object.create(Error.prototype)

const error = {
  /**
   * getr error object which bypassed error handling
   */
  bypass(e) {
    let err = e || new Error('BypassError')
    err.__bypass = true
    return err
  },

  /**
   * @param {Error || String} errObj 
   * @param {Object} errorDisplay  
   * @param {Object} errorData 
   * @returns error Object to be used on server and client
   */
   new(errObj, displayData, contextData) {
    if (errObj.__bypass || errObj.__SURVEY_ERROR ) {
      return errObj
    }

    if (_.isString(errObj)) {
      errObj = new Error(errObj)
    } else if (!(errObj instanceof Error)) {
      errObj = new Error('SurveyError')
    }

    errObj.__SURVEY_ERROR = true

    if (displayData || !errObj.displayData) {
      displayData = displayData || {}
      errObj.displayData = Object.assign({
        // display data
        type: 'error', // error|warning|info
        title: displayData.title || displayData.type || 'error',
        description: 'there_has_been___',
        i18n: true,
        dialog: true,
        silent: false,
        showReloadButton: true,
        showCancelButton: true
      }, displayData)
    }

    if (contextData || !errObj.contextData) {
      errObj.contextData = contextData || null
    }

    return errObj
  },

  handleError(surveyError) {
    switch (surveyError.displayData.type) {
      case 'error':
        console.error(surveyError)
        break
      case 'warning':
        console.info(surveyError)
        break
      case 'info':
        console.info(surveyError)
        break
    }

    store.dispatch('setError', surveyError.displayData)

    if (!surveyError.displayData.dialog) {
      router.replace({ name: 'error' })
    }
  },

  async runtimeError(surveyError) {
    if (surveyError.__bypass) {
      return
    }

    const displayData = surveyError.displayData
    const contextData = surveyError.contextData

    let serverLog = {
      type: 'runtime error',
      message: surveyError.message || null,
      stack: surveyError.stack || null,
      callStack: new Error().stack,
      userAgent: navigator.userAgent || null,
      displayData: displayData,
      contextData: contextData,
      url: window.location.protocol  + '//' + window.location.hostname,
      href: window.location.href
    }

    /* do not wait for async call */
    api.call('runtimeError', {
      data: JSON.parse(JSON.stringify(serverLog)),
      handleError: false
    })

    if (!surveyError.displayData.silent) {
      error.handleError(surveyError)
    }
  }
}

export default error