<template>
  <v-app>
    <v-card
      flat
      height="100vh"
      class="d-flex flex-column pa-0 rounded-0"
    >
      <v-toolbar
        v-if="(!$store.state.error || $store.state.error.dialog) && ($store.state.survey && !$store.state.survey.hideToolbar)"
        dark
        :prominent="backgroundImage"
        :src="backgroundImage"
        style="flex: 0 0 auto;"
      >
        <div class="logo" v-if="companyImage">
          <img :src="companyImage" alt="logo" />
        </div>

        <v-toolbar-title v-if="$store.state.survey">{{ $store.state.survey.label }}</v-toolbar-title> 

        <v-spacer></v-spacer>

        <template v-slot:extension v-if="$store.state.survey.showHomepage">
          <v-tabs
            align-with-title
          >
            <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-card-text fill-height class="d-flex pa-0" style="overflow: auto; flex: 1 1 auto;">
        <router-view></router-view>
      </v-card-text>

      <v-overlay
        :opacity=".3"
        :value="$store.state.loading"
      >
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      
      <v-card-actions
        dark
        class="pa-0"
      >
        <v-row justify="center" no-gutters>
          <v-col class="accent py-4 text-center white--text" cols="12">
            {{ new Date().getFullYear() }} — <strong>TEMA-Q</strong>
          </v-col>
        </v-row>
      </v-card-actions>

      <error-card :dialog="true" />
    </v-card>
  </v-app>
</template>

<script>
  import { t, getLocale } from '@/i18n'

  export default {
    data() {
      return {
      }
    },
    computed: {
      appBarVisible() {
        return this.$route.name !== 'error'
      },
      backgroundImage() {
        if (this.$store.state.startupData) {
          return this.$store.state.startupData.startBackgroundImage ? 'https://dsukjyvr3uysd.cloudfront.net' + this.$store.state.startupData.startBackgroundImage : null
        }
      },
      companyImage() {
        if (this.$store.state.startupData) {
          return this.$store.state.startupData.startCompanyImage ? 'https://dsukjyvr3uysd.cloudfront.net' + this.$store.state.startupData.startCompanyImage : null
        }
      },
      tabParams() {
        return {
          source: this.$store.state.source,
          survey: this.surveyName,
          id: this.$store.state.dataId,
          pw: this.$store.state.pw
        }
      },
      surveyName() {
        return this.$store.state.survey && this.$store.state.survey.name
      },
      tabs() {
        return [
          { 
            id: 1,
            name: t('navigation.home'),
            route: {
              name: 'surveyHome',
              params: this.tabParams
            }
          },
          {
            id: 2,
            name: t('navigation.survey'),
            route: {
              name: 'survey',
              params: this.tabParams
            }
          }
        ]
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss">
@import "~js-datepicker/dist/datepicker.min.css";

@import '~survey-core/survey.min.css';
@import '~survey-core/modern.min.css';
@import "~survey-core/defaultV2.min.css";

.logo {
  position: absolute;
  z-index: -1;
  top: 15px;
  right: 15px;
}

// ---- START SureyJs ----
    
// Fix min-width set to 300px destroys layout for smaller fields
.sv_qstn {
    min-width: 0px !important;
}

.sv_row {
    min-width: 0px !important;
}

// ---- END SureyJs ----
</style>