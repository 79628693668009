import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import surveyJsUtil from '@/services/surveyJs/util'
import surveyJsWidgets from '@/services/surveyJs/widgets'
import error from '@/services/error'
import i18n from '@/i18n'

// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json"

// Import the plugin here
// import { Auth0Plugin } from "./auth"

// components
import ConfirmDialog from '@/components/ConfirmDialog'
import ErrorCard from '@/components/ErrorCard'
import Survey from '@/components/Survey'
import SurveyjsWidgetSurveyCreatorJson from '@/components/surveyjs-widgets/SurveyCreatorJson'
import SurveyjsWidgetSurveyDate from '@/components/surveyjs-widgets/SurveyDate'
//import SurveyjsWidgetSurveyDatepicker from '@/components/surveyjs-widgets/SurveyDatepicker'
import SurveyNavigation from '@/components/SurveyNavigation'

// include components
Vue.component('confirm-dialog', ConfirmDialog)
Vue.component('error-card', ErrorCard)
Vue.component('survey', Survey)
Vue.component('surveyjs-creator-json', SurveyjsWidgetSurveyCreatorJson)
Vue.component('surveyjs-creator-survey-date', SurveyjsWidgetSurveyDate)
//Vue.component('surveyjs-creator-survey-datepicker', SurveyjsWidgetSurveyDatepicker)
Vue.component('survey-navigation', SurveyNavigation)

Vue.config.productionTip = false

Vue.config.errorHandler = (err, vm, info) => {
  error.runtimeError(error.new(
    err,
    { 
      dialog: true
    }
  ))
}

surveyJsUtil.init()
surveyJsWidgets.init()

window.vm = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
