<template>
  <div v-bind:class="{ 'no-dialog': !dialog }">
    <v-dialog
      v-if="dialog"
      persistent
      v-model="showErrorDialog"
      max-width="290"
    >
      <v-card elevation="4" v-if="showError()" class="mx-auto" style="min-width: 250px; max-width: 500px;">
        <v-card-title v-if="isTitle" class="pa-5" style="color: #fff;" :style="{ backgroundColor: getTitleColor() }">
          <v-icon x-large color="#fff" style="margin: 0 10px 0 0;">{{ getTitleIcon() }}</v-icon> {{ getTitle() }}
        </v-card-title>

        <v-card-text v-if="isDescription" class="pa-5">
          {{ getDescription() }}
        </v-card-text>

        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>

          <v-btn
            v-if="$store.state.error.showCancelButton"
            text
            @click.native="cancel"
          >
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn
            v-if="$store.state.error.showReloadButton"
            text
            @click.native="reload"
            color="primary"
          >
            {{ $t('actions.reload') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <div v-if="!dialog" style="display: flex; flex: 1 1 auto; align-items: center; justify-content: center;">
      <v-card elevation="4" v-if="showError()" class="mx-auto" style="min-width: 300px; max-width: 500px;">
        <v-card-title v-if="isTitle" class="pa-5" style="color: #fff;" :style="{ backgroundColor: getTitleColor() }">
          <v-icon x-large color="#fff" style="margin: 0 10px 0 0;">{{ getTitleIcon() }}</v-icon> {{ getTitle() }}
        </v-card-title>

        <v-card-text v-if="isDescription" class="pa-5">
          {{ getDescription() }}
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
  import { t } from '@/i18n'

  export default {
    name: 'ErrorCard',

    data: () => {
      return {
        showErrorDialog: false
      }
    },

    props: {
      dialog: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      cancel() {
        this.showErrorDialog = false
      },
      showError() {
        return !!this.$store.state.error && this.$store.state.error.dialog == this.dialog
      },

      isDescription() {
        return !!this.$store.state.error.description
      },

      isTitle() {
        return !!this.$store.state.error.title
      },

      getDescription() {
        return this.$store.state.error.i18n ? t('errors.descriptions.' + this.$store.state.error.description): this.$store.state.error.description
      },

      getTitle() {
        return this.$store.state.error.i18n ? t('errors.titles.' + this.$store.state.error.title): this.$store.state.error.title
      },

      getTitleColor() {
        switch (this.$store.state.error.type) {
          case 'error':
            return '#c62828'
          case 'warning':
            return '#F9A825'
          case 'info':
            return '#2E7D32'
        }
      },

      getTitleIcon() {
        switch (this.$store.state.error.type) {
          case 'error':
            return 'mdi-alert-circle'
          case 'warning':
            return 'mdi-information-outline'
          case 'info':
            return 'mdi-information-outline'
        }
      },

      reload() {
        window.location.reload(true)
      }
    },

    mounted() {
    },

    watch: {
      '$store.state.error': function(error) {
        if (error) {
          if (this.dialog && error.dialog) {
            this.showErrorDialog = true
          }
        } else {
          if (this.dialog) {
            this.showErrorDialog = false 
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .no-dialog {
    display: flex;
    flex: 1;
  }
</style>
