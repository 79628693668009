<template>
  <v-card-actions v-if="survey" style="display: flex; flex: 0 1 auto;" elevation="2">
    <v-spacer></v-spacer>

    <v-btn :disabled="isFirstPage" v-show="showPrevPage" @click="prevPage">{{ $t('actions.back') }}</v-btn>
    <v-btn :disabled="disableNextPage" v-show="showNextPage" @click="nextPage">{{ $t('actions.continue') }}</v-btn>
    <div style="width: 30px;"></div>
    <v-btn @click="save" v-show="!$store.state.survey.hideSaveProgress" color="orange darken-1" dark>{{ $t('actions.stop_and_save') }}</v-btn>
    <v-btn v-show="isLastPage" @click="complete" color="green darken-1" dark>{{ $t('actions.complete') }}</v-btn>
  </v-card-actions>
</template>

<script>
import surveyJsUtil from '@/services/surveyJs/util'

export default {
  name: 'SurveyNavigation',
  props: {
    survey: Object
  },
  computed: {
    disableNextPage() {
      return this.isLastPage || surveyJsUtil.getSurveyOption(this.survey, 'hideContinue')
    },
    showNextPage() {
      return !this.isLastPage
    },
    showPrevPage() {
      return !this.isFirstPage || !this.isLastPage
    }
  },
  data: () => {
    return {
      isFirstPage: false,
      isLastPage: false
    }
  },
  watch: {
    survey() {
      this.initSurvey()
    }
  },
  methods: {
    complete() {
      this.$emit('onComplete')
    },
    initSurvey() {
      if (this.survey) {
        this.survey.onCurrentPageChanged.add(this.onCurrentPageChanged)
        this.onCurrentPageChanged(this.survey)
      }
    },
    nextPage() {
      this.survey.nextPage()
    },
    onCurrentPageChanged(survey) {
      this.isFirstPage = survey.isFirstPage
      this.isLastPage = survey.isLastPage
    },
    prevPage() {
      this.survey.prevPage()
      },
    save() {
      this.$emit('onSave')
    }
  },
  mounted() {
    this.initSurvey()
  }
}
</script>

<style lang="scss" scoped>
  .nav-left, .nav-right {
    display: flex;
    flex: 1 1 0;

    ::v-deep {
      .v-btn {
        margin: 0 0 0 10px;
      }

      .v-btn:first-child {
        margin: 0;
      }
    }
  }

  .nav-right {
    justify-content: flex-end;
  }
</style>