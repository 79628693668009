import error from '@/services/error'

import axios from 'axios'

const callInfo = (name, data) => {
	const calls = {
		getData: data => ({
			method: 'get',
			url: '/api/' + data.source + '/surveys/' + data.survey + '/data/' + data.id + '/' + data.pw
		}),
		getDocumentationUrl: data => ({
			method: 'get',
			url: '/api/' + data.source + '/surveys/' + data.survey + '/documentation/' + data.id + '/' + data.pw
		}),
		getStartupData: data => ({
			method: 'get',
			url: '/api/' + data.source + '/surveys/' + data.survey + '/startup_data/' + data.id + '/' + data.pw
		}),
		getSurvey: data => ({
			method: 'get',
			url: '/api/' + data.source + '/surveys/' + data.survey + '/get/' + data.id + '/' + data.pw
		}),
		getSurveyJsMapping: data => ({
			method: 'get',
			url: '/api/' + data.source + '/surveys/' + data.survey + '/survey_js_mapping/' + data.collectionName
		}),
		getSources: data => ({
			method: 'get',
			url: '/api/sources'
		}),
		/*getVariables: data => ({
			method: 'get',
			url: '/api/' + data.source + '/variables/' + data.survey
		}),*/
		updateData: data => ({
			method: 'patch',
			url: '/api/' + data.source + '/surveys/' + data.survey + '/data/' + data.id + '/' + data.pw
		}),
		updateTimestamp: data => ({
			method: 'get',
			url: '/api/' + data.source + '/surveys/' + data.survey + '/update_timestamp/' + data.id + '/' + data.pw
		}),
		getTranslations: data => ({
			method: 'get',
			url: '/api/translations/' + data.locale
		}),
		getSourceTranslations: data => ({
			method: 'get',
			url: '/api/'  + data.source + '/translations/' + data.locale
		}),
		getSurveyTranslations: data => ({
			method: 'get',
			url: '/api/'  + data.source + '/surveys/' + data.survey + '/translations/' + data.locale
		}),
		runtimeError: data => ({
			method: 'post',
			url: '/api/error/runtime'
		}),
		sendEmails: data => ({
			method: 'post',
      url: '/api/sources/' + data.source + '/email'
		})
	}
	return calls[name](data)
}

let api = {
	lastCallTime: Date.now(),

	async call(name, options) {
		try {
			api.lastCallTime = Date.now()

			options = Object.assign({
				vars: {},
				params: {},
				data: null,
				handleError: true
			}, options || {}) 
			const { url, method } = callInfo(name, options.vars)

			let requestObj = {
				baseURL: window.location.origin,
				url: url,
				maxContentLength: null,
				method: method,
				params: options.params
			}

			if (options.data) {
				requestObj.data = options.data
			}

			let res = await	axios(requestObj)

			return res
		} catch (e) {
			if (options.handleError) {
				if (e.response) {
					error.handleError(error.new(new Error('API response error'), e.response.data))
				} else {
					error.handleError(error.new(e))
				}

				throw error.bypass(e)
			} else {
				throw e
			}
		} 
	}
}


export default api