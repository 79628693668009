<template>
  <v-dialog
    v-if="ready"
    v-model="dialog"
    :max-width="options.width"
    :persistent="options.persistent"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    @click:outside="cancel"
  >
    <v-card>
      <v-toolbar dark :style="{ backgroundColor: getTitleColor() }" flat>
        <v-toolbar-title style="color: #fff;">
          <v-icon x-large color="#fff" style="margin: 0 10px 0 0;">{{ getTitleIcon() }}</v-icon> {{ options.title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!options.message"
        class="pa-4"
        v-html="options.message"
      ></v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noCancel"
          text
          @click.native="cancel"
          >{{ options.cancelText }}</v-btn
        >
        <v-btn
          v-if="!options.noOk"
          text
          @click.native="ok"
          >{{ options.okText }}</v-btn
        >
        <v-btn
          v-for="(additionalButton, index) in options.additionalButtons"
          :key="index"
          text
          @click.native="additionalButton.action"
          >{{ additionalButton.text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { t } from '@/i18n'

  const getDefaultOptions = () => {
    return {
      cancelText: t('actions.cancel'),
      message: null, 
      noCancel: false,
      noOk: false,
      okText: t('actions.ok'),
      persistent: false,
      title: t('dialogs.titles.confirm'),
      additionalButtons: [],
      type: 'info',
      width: 400,
      zIndex: 200
    }
  }

  export default {
    name: "ConfirmDialog",

    data() {
      return {
        dialog: false,
        options: {},
        ready: false,
        reject: null,
        resolve: null
      };
    },

    methods: {
      cancel() {
        if (!this.options.persistent) {
          this.resolve(false)
          this.dialog = false
        }
      },

      getTitleColor() {
        switch (this.options.type) {
          case 'error':
            return '#c62828'
          case 'warning':
            return '#F9A825'
          case 'info':
            return '#2E7D32'
        }
      },

      getTitleIcon() {
        switch (this.options.type) {
          case 'error':
            return 'mdi-alert-circle'
          case 'warning':
            return 'mdi-information-outline'
          case 'info':
            return 'mdi-information-outline'
        }
      },

      open(options) {
        this.dialog = true
        this.options = Object.assign({}, getDefaultOptions(), options)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        });
      },
      ok() {
        this.resolve(true)
        this.dialog = false
      },
    },

    mounted() {
      this.otptions = getDefaultOptions()
      this.ready = true
    }
  };
</script>

<style lang="scss" scoped>
</style>