<template>
  <div><input :class="classObject" autocomplete="new-password" ref="input" /></div>
</template>

<script>
import i18n from '@/i18n'
import datepicker from 'js-datepicker'
import dateFormat from 'dateformat'

const lang = {
  en: {
    overlayButton: 'Apply',
    overlayPlaceholder: 'ENTER YEAR'
  },
  de: {
    customMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    overlayButton: 'Anwenden',
    overlayPlaceholder: 'JAHR EINTRAGEN',
    startDay: 1
  }
}

export default {
  name: 'surveyjs-date',
  computed: {
    classObject() {
      return {
        /*
        * set the class "sd-input" only if the theme "defaultV2" is used
        */
        'sd-input': this.question.isDefaultV2Theme
      }
    }
  },
  props: {
    question: Object
  },
  methods: {
    
  },
  async mounted() {
    let question = this.question

    let inputElement = this.$refs['input']

    inputElement.value = question.value === undefined ? '' : question.value

    inputElement.onInput = function(event) {
      let value = event.target.value ? event.target.value : null
      question.survey.setValue(question.name, value)
    }

    inputElement._enable = function() {
      inputElement.readOnly = false

      inputElement.addEventListener('input', inputElement.onInput)
  
      let options = {
        position: 'bl',
        formatter: (input, date, instance) => {
          input.value = dateFormat(date, "yyyy-mm-dd")
        },
        onHide: instance => {
          let dateStr = null
  
          if (Date.parse(inputElement.value)) {
            dateStr = dateFormat(new Date(inputElement.value), "yyyy-mm-dd")
          }
  
          question.survey.setValue(question.name, dateStr)
          
          inputElement.value = dateStr
        }
      }
  
      Object.assign(options, lang[i18n.locale] || {})
        
      inputElement.datepicker = datepicker(inputElement, options)
    }

    inputElement._disable = function() {
      if (inputElement.datepicker) {
        inputElement.readOnly = true

        inputElement.removeEventListener("input", inputElement.onInput)

        inputElement.datepicker.remove()

        delete inputElement.datepicker
      }
    }

    var updateReadOnly = function () {
      if (question.isReadOnly) {
        inputElement._disable()
      } else {
        inputElement._enable()
      }
    }

    // Set initial read-only state
    updateReadOnly()

    // Update editor read-only state on changing question readOnly property
    question.readOnlyChangedCallback = function () {
      updateReadOnly()
    }
  },

  beforeDestroy() {
    let inputElement = this.$refs['input']

    inputElement._disable()

    delete inputElement._enable
    delete inputElement._disable
  }
}
</script>

<style lang="scss" scoped>
</style>