export default {
  AsyncPromiseQueue(run) {
    // Setting up an async queue so this function is only run in sequence
      
    // Queue for function call
    const queue = []
    
    return (...args) => {
      return new Promise(resolve => {
        queue.push([args, resolve])

        const next = () => {
          let queueReturn = run(...queue[0][0])
          let runQueueReturn = (...args) => {
            queue[0][1](...args)
            queue.shift()
            if (queue.length != 0) {
              next()
            }
          }

          if (queueReturn instanceof Promise) {              
            queueReturn.then((...args) => {
              runQueueReturn(...args)
            })
          } else {
            runQueueReturn(queueReturn)
          }
        }

        if (queue.length == 1) {
          next()
        }
      })
    }
  }
}