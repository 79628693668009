<template>
   <div style="flex: 1 1 auto; overflow: auto;" ref="survey"></div>
</template>

<script>
export default {
  name: 'Survey',
  props: {
    survey: Object
  },
  methods: {
    renderSurvey(survey) {
      survey && survey.render(this.$refs.survey)
    }
  },
  watch: {
    survey(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (oldVal) {
          oldVal.dispose()
        }
        this.renderSurvey(newVal)
      }
    }
  },
  mounted() {
    this.renderSurvey(this.survey)    
  }
}
</script>
