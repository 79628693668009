import { v4 as uuidv4 } from 'uuid'

import store from '@/store'

const storageUtil = {
  setTimeoutKey(timeoutKey) {
    localStorage.setItem('timeoutKey_v1.0', timeoutKey)
    store.dispatch('setTimeoutKey', timeoutKey)
  },

  getTimeoutKey() {
    let timeoutKey = store.state.timeoutKey || localStorage.getItem('timeoutKey_v1.0')

    if (!timeoutKey) {
      timeoutKey = uuidv4()
      storageUtil.setTimeoutKey(timeoutKey)
    }

    return timeoutKey
  }
}

export default storageUtil